import { getInitConfig } from 'ziphy-web-shared';
export var isLocal = function () {
    return ['local', 'dev'].includes(getInitConfig().appEnv);
};
export var isDev = function () {
    return ['dev'].includes(getInitConfig().appEnv);
};
export var isStage = function () {
    return ['staging'].includes(getInitConfig().appEnv);
};
export var isProd = function () {
    return ['production'].includes(getInitConfig().appEnv);
};
export var PUBLIC_URL = process.env.PUBLIC_URL;
