import moment from 'moment-timezone';
export function objectToIso(type, obj) {
    if (obj === void 0) { obj = {}; }
    var year = obj.year, month = obj.month, day = obj.day, hour = obj.hour, minute = obj.minute, second = obj.second, microsecond = obj.microsecond, utcoffset = obj.utcoffset;
    var result = '';
    if (type === 'datetime') {
        result = moment.utc([year, month - 1, day, hour, minute, second]).utcOffset(-utcoffset);
        result = result.format('YYYY-MM-DDTHH:mm:ss') + 'Z';
        // if (utcoffset !== 0) {
        //   console.log(`${year}-${month}-${day}T${hour}:${minute}:${second} ${utcoffset}`, result)
        // }
    }
    else if (type === 'date') {
        result = moment.utc([year, month - 1, day]);
        result = result.format('YYYY-MM-DD');
    }
    return result;
}
export function isoToObject(type, value) {
    var date = moment.utc(value).toObject();
    if (type === 'datetime') {
        return {
            _type: 'datetime',
            year: date.years,
            month: date.months + 1,
            day: date.date,
            //
            hour: date.hours,
            minute: date.minutes,
            second: date.seconds,
            microsecond: date.milliseconds,
            utcoffset: 0,
        };
    }
    else if (type === 'date') {
        return {
            _type: 'date',
            year: date.years,
            month: date.months + 1,
            day: date.date,
        };
    }
}
