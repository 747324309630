var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useEffect } from 'react';
import { makeAutoObservable } from 'mobx';
import { isMobile, isMobileOnly, isTablet } from 'react-device-detect';
var defaultWidth = { xs: 0, sm: 576, md: 768, lg: 992, xl: 1200 };
var defaultHeight = { xs: 0, sm: 360, md: 700, lg: 800, xl: 1080 };
var WindowSizeStore = /** @class */ (function () {
    function WindowSizeStore(props) {
        this.breakpoints = { width: defaultWidth, height: defaultHeight };
        this.width = window.innerWidth;
        this.height = window.innerHeight;
        if (props === null || props === void 0 ? void 0 : props.width) {
            this.breakpoints.width = __assign(__assign({}, this.breakpoints.width), props.width);
        }
        if (props === null || props === void 0 ? void 0 : props.height) {
            this.breakpoints.height = __assign(__assign({}, this.breakpoints.height), props.height);
        }
        makeAutoObservable(this);
    }
    WindowSizeStore.prototype.handleResize = function () {
        this.width = window.innerWidth;
        this.height = window.innerHeight;
    };
    Object.defineProperty(WindowSizeStore.prototype, "isXS", {
        get: function () {
            return this.width >= this.breakpoints.width.xs && this.width < this.breakpoints.width.sm;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(WindowSizeStore.prototype, "isSM", {
        get: function () {
            return this.width >= this.breakpoints.width.sm && this.width < this.breakpoints.width.md;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(WindowSizeStore.prototype, "isMD", {
        get: function () {
            return this.width >= this.breakpoints.width.md && this.width < this.breakpoints.width.lg;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(WindowSizeStore.prototype, "isLG", {
        get: function () {
            return this.width >= this.breakpoints.width.lg && this.width < this.breakpoints.width.xl;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(WindowSizeStore.prototype, "isXL", {
        get: function () {
            return this.width >= this.breakpoints.width.xl;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(WindowSizeStore.prototype, "isMobileSize", {
        get: function () {
            return this.maxLG;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(WindowSizeStore.prototype, "isMobile", {
        get: function () {
            return isMobile || this.isMobileSize;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(WindowSizeStore.prototype, "isMobileOnly", {
        get: function () {
            return isMobileOnly || this.maxMD;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(WindowSizeStore.prototype, "isTabletSize", {
        get: function () {
            return this.maxXL;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(WindowSizeStore.prototype, "isTablet", {
        get: function () {
            return isTablet || this.isTabletSize;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(WindowSizeStore.prototype, "minXS", {
        get: function () {
            return this.width >= this.breakpoints.width.xs;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(WindowSizeStore.prototype, "minSM", {
        get: function () {
            return this.width >= this.breakpoints.width.sm;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(WindowSizeStore.prototype, "minMD", {
        get: function () {
            return this.width >= this.breakpoints.width.md;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(WindowSizeStore.prototype, "minLG", {
        get: function () {
            return this.width >= this.breakpoints.width.lg;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(WindowSizeStore.prototype, "minXL", {
        get: function () {
            return this.width >= this.breakpoints.width.xl;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(WindowSizeStore.prototype, "maxXS", {
        get: function () {
            return this.width < this.breakpoints.width.xs;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(WindowSizeStore.prototype, "maxSM", {
        get: function () {
            return this.width < this.breakpoints.width.sm;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(WindowSizeStore.prototype, "maxMD", {
        get: function () {
            return this.width < this.breakpoints.width.md;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(WindowSizeStore.prototype, "maxLG", {
        get: function () {
            return this.width < this.breakpoints.width.lg;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(WindowSizeStore.prototype, "maxXL", {
        get: function () {
            return this.width < this.breakpoints.width.xl;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(WindowSizeStore.prototype, "hMinXS", {
        get: function () {
            return this.height >= this.breakpoints.height.xs;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(WindowSizeStore.prototype, "hMinSM", {
        get: function () {
            return this.height >= this.breakpoints.height.sm;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(WindowSizeStore.prototype, "hMinMD", {
        get: function () {
            return this.height >= this.breakpoints.height.md;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(WindowSizeStore.prototype, "hMinLG", {
        get: function () {
            return this.height >= this.breakpoints.height.lg;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(WindowSizeStore.prototype, "hMinXL", {
        get: function () {
            return this.height >= this.breakpoints.height.xl;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(WindowSizeStore.prototype, "hMaxXS", {
        get: function () {
            return this.height < this.breakpoints.height.xs;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(WindowSizeStore.prototype, "hMaxSM", {
        get: function () {
            return this.height < this.breakpoints.height.sm;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(WindowSizeStore.prototype, "hMaxMD", {
        get: function () {
            return this.height < this.breakpoints.height.md;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(WindowSizeStore.prototype, "hMaxLG", {
        get: function () {
            return this.height < this.breakpoints.height.lg;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(WindowSizeStore.prototype, "hMaxXL", {
        get: function () {
            return this.height < this.breakpoints.height.xl;
        },
        enumerable: false,
        configurable: true
    });
    return WindowSizeStore;
}());
export var $windowSize = new WindowSizeStore();
export var useInitWindowSize = function (props) {
    useEffect(function () {
        if (props) {
            $windowSize = new WindowSizeStore(props);
        }
        function handleResize() {
            $windowSize.handleResize();
        }
        window.addEventListener('resize', handleResize);
        return function () { return window.removeEventListener('resize', handleResize); };
    }, []);
};
