var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { cssTransition, toast } from 'react-toastify';
import styles from './Alerts.module.scss';
var Zoom = cssTransition({ enter: 'slide-in-top', exit: 'fade-out' });
var defaultOptions = {
    position: 'top-center',
    icon: false,
    theme: 'colored',
    closeButton: false,
    transition: Zoom,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
};
var ShowAlert = /** @class */ (function () {
    function ShowAlert() {
    }
    ShowAlert.prototype.success = function (content, options) {
        var customOptions = __assign(__assign(__assign({}, defaultOptions), { toastId: JSON.stringify(content) }), options);
        return toast.success(_jsx(Content, { content: content }), customOptions);
    };
    ShowAlert.prototype.error = function (content, options) {
        var customOptions = __assign(__assign(__assign({}, defaultOptions), { toastId: JSON.stringify(content) }), options);
        return toast.error(_jsx(Content, { content: content }), customOptions);
    };
    return ShowAlert;
}());
export var showAlert = new ShowAlert();
function Content(_a) {
    var content = _a.content;
    return (_jsx(_Fragment, { children: typeof content === 'object' && ('title' in content || 'content' in content) ? (_jsxs(_Fragment, { children: [(content === null || content === void 0 ? void 0 : content.title) && _jsx("div", __assign({ className: styles.title }, { children: content.title })), _jsx("div", __assign({ className: styles.content }, { children: content.content }))] })) : (_jsx("div", __assign({ className: styles.content }, { children: content }))) }));
}
