import { defaults } from 'chart.js';
defaults.color = '#757575';
defaults.font.family =
    '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"';
defaults.font.lineHeight = 1.2;
defaults.font.size = 12;
defaults.scale.grid.color = '#ebebeb';
defaults.scale.grid.borderColor = '#d6d6d6';
defaults.maintainAspectRatio = false;
defaults.animation = { duration: 100 };
defaults.hover.mode = null;
