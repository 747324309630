var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import _ from 'lodash';
export function delay(ms) {
    return new Promise(function (resolve) {
        setTimeout(function () { return resolve(); }, ms);
    });
}
export var delayedRequest = function (callback, delayLimit) {
    if (delayLimit === void 0) { delayLimit = 1000; }
    return __awaiter(void 0, void 0, Promise, function () {
        var start, result, end, delta;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    start = new Date().getTime();
                    return [4 /*yield*/, callback()];
                case 1:
                    result = _a.sent();
                    end = new Date().getTime();
                    delta = end - start;
                    if (!(delta < delayLimit)) return [3 /*break*/, 3];
                    return [4 /*yield*/, delay(delayLimit - delta)];
                case 2:
                    _a.sent();
                    _a.label = 3;
                case 3: return [2 /*return*/, result];
            }
        });
    });
};
export function waitForVariable(callback, timeout, maxTimeout) {
    if (timeout === void 0) { timeout = 250; }
    if (maxTimeout === void 0) { maxTimeout = 3000; }
    return __awaiter(this, void 0, Promise, function () {
        var waiting, counter, condition;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    waiting = true;
                    counter = 0;
                    condition = typeof callback === 'string' ? function () { return _.get(window, callback); } : callback;
                    _a.label = 1;
                case 1:
                    if (!(waiting && typeof condition === 'function' && !condition())) return [3 /*break*/, 5];
                    if (!(counter <= maxTimeout)) return [3 /*break*/, 3];
                    return [4 /*yield*/, new Promise(function (resolve) { return setTimeout(resolve, timeout); })];
                case 2:
                    _a.sent();
                    counter += timeout;
                    return [3 /*break*/, 4];
                case 3:
                    waiting = false;
                    _a.label = 4;
                case 4: return [3 /*break*/, 1];
                case 5: return [2 /*return*/];
            }
        });
    });
}
export function wrapPromise(promise) {
    var status = 'pending';
    var result;
    var suspender = promise.then(function (r) {
        result = r;
        status = 'success';
    }, function (e) {
        result = e;
        status = 'error';
    });
    return {
        read: function () {
            if (status === 'pending') {
                throw suspender;
            }
            else if (status === 'error') {
                throw result;
            }
            else if (status === 'success') {
                return result;
            }
        },
    };
}
