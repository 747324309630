import _ from 'lodash';
export function splitBloodPressure(value) {
    if (value === void 0) { value = ''; }
    var result = value.split('/');
    return {
        systolic: result[0],
        diastolic: result[1],
    };
}
export function joinHeightToInches(foot, inch) {
    return foot * 12 + inch * 1;
}
export function splitHeightFromInches(height, asString) {
    if (asString === void 0) { asString = false; }
    var foot = Math.floor(height / 12);
    var inch = _.round(height - foot * 12, 2);
    if (asString) {
        if (!foot && !inch) {
            return '';
        }
        if (inch) {
            return "".concat(foot, "'").concat(inch, "\"");
        }
        else {
            return "".concat(foot, "'");
        }
    }
    return { foot: foot, inch: inch };
}
export function getBodyMassIndex(weight, height) {
    return ((weight / Math.pow(height, 2)) * 703).toFixed(1);
}
