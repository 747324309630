var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import _ from 'lodash';
import { isoToObject, objectToIso, toNumberIfPossible, toSnakeCase, } from 'ziphy-web-shared/basic/helpers';
export var skipAlertErrorCodes = [
    'error.jwt.invalid',
    'error.jwt.expired',
    'error.auth.expired_session',
    'error.auth.high_rate_captcha',
    'error.resource.unreachable',
    'ERR_NETWORK',
    'ECONNABORTED',
];
export var skipRGErrorCodes = [
    'error.catch.internal',
    'error.auth.high_rate_captcha',
    //
];
export var customMessages = _.orderBy([
    {
        code: 'error.resource.precondition_failed',
        data: { method: 'appointments.rebook' },
        msg: 'api_errors.appointments.rebook',
    },
    {
        code: 'error.resource.precondition_failed',
        data: { method: 'appointments.cancel' },
        msg: 'api_errors.appointments.cancel',
    },
    {
        code: 'error.rpc.internal',
        data: { method: 'appointments.create' },
        msg: 'api_errors.appointments.create',
        msgPrefix: 'RPC Internal: ',
    },
    {
        code: 'error.rpc.internal',
        msg: 'api_errors.default',
        msgPrefix: 'RPC Internal: ',
    },
    {
        code: 'error.catch.internal',
        msg: 'api_errors.default',
        msgPrefix: 'API CATCH: ',
    },
], function (x) { return Object.keys(x.data || {}).length; }, 'desc');
export function convertKeysToCamel(data) {
    // @ts-ignore
    var processVal = function (val) {
        return typeof val !== 'object' || val === null
            ? val
            : Array.isArray(val)
                ? val.map(processVal)
                : renameKeys(val);
    };
    // @ts-ignore
    var renameKeys = function (obj) {
        return _.fromPairs(_.entries(obj).map(function (_a) {
            var key = _a[0], val = _a[1];
            return [
                key.replace(/_(.)/g, function (g) { return g[1].toUpperCase(); }),
                processVal(val),
            ];
        }));
    };
    return _.isArray(data) ? processVal(data) : renameKeys(data);
}
export function convertKeysToSnake(data) {
    // @ts-ignore
    function processVal(val) {
        if (typeof val !== 'object' || val === null) {
            return val;
        }
        else {
            if (Array.isArray(val)) {
                return val.map(processVal);
            }
            else {
                return renameKeys(val);
            }
        }
    }
    // @ts-ignore
    function renameKeys(obj) {
        return _.fromPairs(_.entries(obj).map(function (_a) {
            var key = _a[0], val = _a[1];
            var tmp = key.split('|');
            var resultKey = tmp[0];
            if (tmp[1] === 'strict') {
            }
            else {
                resultKey = toSnakeCase(key).toLowerCase();
            }
            return [resultKey, processVal(val)];
        }));
    }
    return _.isArray(data) ? processVal(data) : renameKeys(data);
}
export function convertArrayValuesToSnake(value) {
    if (_.isArray(value)) {
        value = _.map(value, function (x) { return convertArrayValuesToSnake(x); });
    }
    else if (_.isObjectLike(value)) {
        value = _.mapValues(value, function (x) { return convertArrayValuesToSnake(x); });
    }
    else {
        value = toSnakeCase(value).toLowerCase();
    }
    return value;
}
export function prepareRequestData(method, data) {
    return _.cloneDeepWith(data, function (item, key) {
        // todo: update to $strict:
        if (_.isString(item) && item.split('|')[1] === 'strict') {
            return item.split('|')[0];
        }
        // todo: update to $toNumber:
        if (_.isString(item) && item.split('|')[1] === 'toNumber') {
            return parseFloat(item.split('|')[0]);
        }
        if (_.isString(item) && item.indexOf('$datetime:') !== -1) {
            var value = item.slice('$datetime:'.length);
            return isoToObject('datetime', value);
        }
        else if (_.isString(item) && item.indexOf('$date:') !== -1) {
            var value = item.slice('$date:'.length);
            return isoToObject('date', value);
            // todo: update to $toNumber:
        }
        else if (key === 'coords' && !_.isEmpty(item)) {
            return [item.lat, item.lng];
        }
        else if (_.isString(key) &&
            (key === 'id' || key.slice(-2) === 'Id' || key.slice(-3) === '_id') &&
            _.isString(item)) {
            return toNumberIfPossible(item);
        }
    });
}
export function prepareResponseData(data) {
    var expanded = data.expanded;
    if (expanded) {
        delete data.expanded;
        expanded = _.mapValues(expanded, function (item) {
            if (item._type === 'mapping') {
                item = item.items.map(function (x) { return x.value; });
            }
            return item;
        });
        data = __assign(__assign({}, data), expanded);
    }
    return convertKeysToCamel(_.cloneDeepWith(data, function (item, key) {
        // @ts-ignore
        var type = _.isObject(item) && item._type;
        if (_.isString(item) && item.indexOf('$datetime:') === 0) {
            return item.slice('$datetime:'.length) + 'Z';
        }
        else if (type === 'datetime') {
            return objectToIso(type, item);
        }
        else if (_.isString(item) && item.indexOf('$date:') === 0) {
            return item.slice('$date:'.length);
        }
        else if (type === 'date') {
            return objectToIso(type, item);
        }
        else if (type === 'bitstring') {
            return item.value;
        }
        else if (_.isString(item) && item.indexOf('$bitstring:') === 0) {
            return item.slice('$bitstring:'.length);
        }
        else if (key === 'coords') {
            if (_.isEmpty(item)) {
                return {};
            }
            return { lat: item[0], lng: item[1] };
        }
        else if (type === 'decimal') {
            return +item.value;
        }
    }));
}
export function parseJwt(token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window
        .atob(base64)
        .split('')
        .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    })
        .join(''));
    return JSON.parse(jsonPayload);
}
