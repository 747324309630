var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { t } from 'i18next';
import _ from 'lodash';
import { BaseService } from 'ziphy-web-shared/basic/api/baseService';
import { showAlert } from 'ziphy-web-shared/basic/lib/utilities';
import { NotesEncounterStatuses } from 'ziphy-web-shared/basic/models';
var isDuplicateResourceError = function (res) {
    var _a, _b;
    var reason = (_b = (_a = res.error) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.reason;
    return /The code of .* already exists .*/.test(reason);
};
var Notes = /** @class */ (function (_super) {
    __extends(Notes, _super);
    function Notes(props) {
        return _super.call(this, props) || this;
    }
    Notes.prototype.readSignature = function (params, options) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.api.fetchLoggedV3('patient_stories.get_notes_signature', params, options)];
            });
        });
    };
    Notes.prototype.listSignatures = function (params, options) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.api.fetchLoggedV3('patient_stories.get_notes_signatures', params, options)];
            });
        });
    };
    Notes.prototype.listStatuses = function (params, options) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.api.fetchLoggedV3("patient_stories.get_notes_status", params, options)];
            });
        });
    };
    Notes.prototype.signNotes = function (params, options) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.api.fetchLoggedV3('patient_stories.sign_notes', params, options)];
            });
        });
    };
    Notes.prototype.reviewSection = function (params, options) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.api.fetchLoggedV3('patient_stories.review_notes', params, options)];
            });
        });
    };
    Notes.prototype.readNotesPdf = function (params, options) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.api.fetchLoggedV3('patient_stories.get_notes_pdf', params, options)];
            });
        });
    };
    /*
       Saving
       */
    Notes.prototype.saveNotes = function (params, options) {
        var _a;
        return __awaiter(this, void 0, Promise, function () {
            var res;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!(params.encounterStatus === NotesEncounterStatuses.CANCELED)) return [3 /*break*/, 1];
                        // temp hack until backend not fix locking for canceled encounters
                        res = {
                            meta: {},
                            result: null,
                            error: {
                                code: 'error.resource.precondition_failed',
                                message: 'Precondition failed',
                                data: {
                                    reason: 'Notes are locked for this encounter',
                                    method: 'patient_stories.save_notes',
                                    version: [3, 0],
                                },
                            },
                            warnings: [],
                        };
                        return [3 /*break*/, 3];
                    case 1: return [4 /*yield*/, this.api.fetchLoggedV3('patient_stories.save_notes', _.omit(params, ['encounterStatus']), __assign({ skipAlert: true }, options))];
                    case 2:
                        res = _b.sent();
                        _b.label = 3;
                    case 3:
                        if (res && ((_a = res.error) === null || _a === void 0 ? void 0 : _a.code) === 'error.resource.precondition_failed') {
                            if (isDuplicateResourceError(res)) {
                                showAlert.error(t('ps.notes.alert.exiting_resource'));
                            }
                            else if (params.encounterStatus === NotesEncounterStatuses.CANCELED) {
                                showAlert.error(t('ps.error.precondition_failed.save_canceled_encounter'));
                            }
                            else if (params.encounterStatus === NotesEncounterStatuses.NOT_STARTED) {
                                showAlert.error(t('ps.error.precondition_failed.save_before_encounter_start'));
                            }
                            else {
                                showAlert.error(t('ps.error.precondition_failed.save_after_encounter_start'));
                            }
                        }
                        return [2 /*return*/, res];
                }
            });
        });
    };
    Notes.prototype.saveAddendum = function (params, options) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.api.fetchLoggedV3('patient_stories.save_addendum', params, options)];
            });
        });
    };
    // /*
    //    Reading
    //    */
    Notes.prototype.readResource = function (params, options) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.api.fetchLoggedV3('patient_stories.get_resource', params, options)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    Notes.prototype.readResourceFullHistory = function (params, options) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.api.fetchLoggedV3('patient_stories.get_derivation_history', params, options)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    Notes.prototype.readNotes = function (params, options) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.api.fetchLoggedV3('patient_stories.read_notes', params, options)];
            });
        });
    };
    Notes.prototype.readSignedNotes = function (params, options) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.api.fetchLoggedV3('patient_stories.read_signed_notes', params, options)];
            });
        });
    };
    Notes.prototype.readNotesHistory = function (params, options) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                params = __assign({ includeCurrent: false }, params);
                return [2 /*return*/, this.api.fetchLoggedV3('patient_stories.read_history_notes', params, options)];
            });
        });
    };
    return Notes;
}(BaseService));
export { Notes };
