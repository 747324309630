import { Api } from 'ziphy-web-shared/basic/api/clients/myack';
import { Accounts, Auth, Calls, ChatMessages, Contacts, Documents, DocumentTemplates, Encounters, Events, InsuranceProviders, Insurances, Notes, Offices, Patients, PaymentMethods, Places, Practices, PracticeServices, PsPatients, Roles, Services, Sessions, Settings, SsQuestions, StubPlugin, Symptoms, Users, Vouchers, } from 'ziphy-web-shared/basic/api/services';
import { Addresses } from 'ziphy-web-shared/basic/api/services/addresses';
export var apiService;
export var accountsService;
export var addressesService;
export var authService;
export var callsService;
export var chatMessagesService;
export var contactsService;
export var documentsService;
export var documentTemplatesService;
export var encountersService;
export var eventsService;
export var insuranceProvidersService;
export var insurancesService;
export var notesService;
export var officesService;
export var patientsService;
export var paymentMethodsService;
export var placesService;
export var practices;
export var practiceServicesService;
export var psPatientsService;
export var rolesService;
export var servicesService;
export var settingsService;
export var sessionsService;
export var ssQuestionsService;
export var stubPluginService;
export var symptomsService;
export var usersService;
export var vouchersService;
export var initServices = function (props) {
    var initData = {
        baseUrl: props.urls.baseUrl,
        getAccessToken: props.getAccessToken,
        setNeedCaptcha: props.setNeedCaptcha,
    };
    apiService = new Api(initData);
    accountsService = new Accounts(initData);
    addressesService = new Addresses(initData);
    authService = new Auth(initData);
    callsService = new Calls(initData);
    chatMessagesService = new ChatMessages(initData);
    contactsService = new Contacts(initData);
    documentsService = new Documents(initData);
    documentTemplatesService = new DocumentTemplates(initData);
    encountersService = new Encounters(initData);
    eventsService = new Events(initData);
    insuranceProvidersService = new InsuranceProviders(initData);
    insurancesService = new Insurances(initData);
    notesService = new Notes(initData);
    officesService = new Offices(initData);
    patientsService = new Patients(initData);
    paymentMethodsService = new PaymentMethods(initData);
    placesService = new Places(initData);
    practices = new Practices(initData);
    practiceServicesService = new PracticeServices(initData);
    psPatientsService = new PsPatients(initData);
    rolesService = new Roles(initData);
    servicesService = new Services(initData);
    settingsService = new Settings(initData);
    sessionsService = new Sessions(initData);
    ssQuestionsService = new SsQuestions(initData);
    stubPluginService = new StubPlugin(initData);
    symptomsService = new Symptoms(initData);
    usersService = new Users(initData);
    vouchersService = new Vouchers(initData);
};
