var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import _ from 'lodash';
import { myackAxiosInstance } from 'ziphy-web-shared/basic/api/clients/myack';
export function openInNewTab(url) {
    var newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) {
        newWindow.opener = null;
    }
}
export function callToPhone(number) {
    window.location.href = 'tel:' + number;
}
export function getFileNameFromUrl(url) {
    if (url) {
        var m = url.toString().match(/.*\/(.+?)\./);
        if (m && m.length > 1) {
            return m[1];
        }
    }
    return '';
}
export function nativeScrollTo(id, params) {
    if (id === void 0) { id = ''; }
    if (params === void 0) { params = {}; }
    if (id) {
        var el = document.querySelector('#' + id);
        if (el) {
            el.scrollIntoView(params);
        }
    }
}
export function fetchBlob(url) {
    return __awaiter(this, void 0, Promise, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, myackAxiosInstance.get(url, { responseType: 'blob' })];
                case 1: return [2 /*return*/, _a.sent()];
            }
        });
    });
}
export function downloadBy(_a) {
    var _b = _a.url, url = _b === void 0 ? '' : _b, blob = _a.blob, _c = _a.prefetch, prefetch = _c === void 0 ? false : _c, _d = _a.fileName, fileName = _d === void 0 ? 'filename' : _d;
    return __awaiter(this, void 0, Promise, function () {
        var response, e_1, link;
        return __generator(this, function (_e) {
            switch (_e.label) {
                case 0:
                    if (!(url && prefetch)) return [3 /*break*/, 4];
                    _e.label = 1;
                case 1:
                    _e.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, fetchBlob(url)];
                case 2:
                    response = _e.sent();
                    blob = response.data;
                    return [3 /*break*/, 4];
                case 3:
                    e_1 = _e.sent();
                    return [3 /*break*/, 4];
                case 4:
                    if (blob instanceof Blob) {
                        url = URL.createObjectURL(blob);
                    }
                    link = document.createElement('a');
                    link.href = url;
                    link.download = fileName;
                    document.body.appendChild(link);
                    link.dispatchEvent(new MouseEvent('click', {
                        bubbles: true,
                        cancelable: true,
                        view: window,
                    }));
                    document.body.removeChild(link);
                    if (url)
                        URL.revokeObjectURL(url);
                    return [2 /*return*/];
            }
        });
    });
}
export function removeHttp(url, removeWWW) {
    if (removeWWW === void 0) { removeWWW = true; }
    var regex = removeWWW ? /^https?:\/\/(www.)?/ : /^https?:\/\//;
    return _.replace(url, regex, '');
}
export function isUrl(url) {
    return url.startsWith('http');
}
export function loadScript(src, onLoad) {
    if (onLoad === void 0) { onLoad = function () { }; }
    var script = document.createElement('script');
    script.setAttribute('src', src);
    script.onload = onLoad;
    document.head.appendChild(script);
}
