import mixpanel from 'mixpanel-browser';
import { analytics, ZipAnalytics } from '../zipAnalytics';
var Mixpanel = /** @class */ (function () {
    function Mixpanel(token, config) {
        this.EVENT_NAV = 'transition';
        this.EVENT_ACTION = 'action';
        this.EVENT_ACTION_COUNT = 'action-count';
        this.MAX_CACHED_EVENTS = 20;
        this.MIN_BATCH_SIZE = 4;
        this.BATCH_TIME_MS = 120000;
        this.apiToken = token;
        this.apiConfig = config;
        this.navPage = '';
        this.initialized = false;
        this.lastEventsTime = Date.now();
        this.event_list = [];
        this.stateList = [];
        this.stateDeltaList = [];
        this.lastEventName = '';
        this.lastEventSent = {};
        this.lastEventRepeats = 0;
    }
    Mixpanel.prototype.register = function () {
        if (!this.initialized && this.apiToken) {
            mixpanel === null || mixpanel === void 0 ? void 0 : mixpanel.init(this.apiToken, this.apiConfig);
            this.initialized = true;
        }
    };
    Mixpanel.prototype.track = function (event, event_data, priority) {
        if (priority === void 0) { priority = false; }
        if (this.initialized) {
            if (!event_data) {
                event_data = {};
            }
            if (priority) {
                event_data.current = this.navPage ? this.navPage : '';
                mixpanel === null || mixpanel === void 0 ? void 0 : mixpanel.track(event, event_data);
            }
            if (this.navPage && !event_data.current) {
                event_data.c = this.navPage;
            }
            var eventsMatch = this.lastEventName === event;
            if (eventsMatch) {
                for (var key in this.lastEventSent) {
                    if (key !== 'ev' && key !== 'ts') {
                        if (event_data[key] !== this.lastEventSent[key]) {
                            eventsMatch = false;
                            break;
                        }
                    }
                }
                if (eventsMatch) {
                    this.lastEventRepeats += 1;
                    return;
                }
            }
            if (!eventsMatch && this.lastEventRepeats != 0) {
                this.lastEventSent.ev = this.lastEventName;
                this.lastEventSent.ts = new Date().getTime();
                this.lastEventSent.rp = this.lastEventRepeats;
                this.event_list.push(this.lastEventSent);
                this.pushAnalytics(false);
                this.lastEventRepeats = 0;
            }
            this.lastEventName = event;
            this.lastEventSent = event_data;
            event_data.ev = event;
            event_data.ts = new Date().getTime();
            this.event_list.push(event_data);
            this.pushAnalytics(false);
        }
    };
    Mixpanel.prototype.startSession = function (id, profile) {
        if (this.initialized) {
            mixpanel === null || mixpanel === void 0 ? void 0 : mixpanel.identify(id);
            this.track('session', profile, true);
            if (profile) {
                mixpanel === null || mixpanel === void 0 ? void 0 : mixpanel.people.set(profile);
                mixpanel === null || mixpanel === void 0 ? void 0 : mixpanel.register({
                    auth: id,
                });
            }
        }
    };
    Mixpanel.prototype.endSession = function () {
        if (this.initialized) {
            this.track('session-end', {}, true);
            mixpanel === null || mixpanel === void 0 ? void 0 : mixpanel.reset();
        }
    };
    Mixpanel.prototype.error = function (event, data, priority) {
        if (priority === void 0) { priority = false; }
        if (!data) {
            data = {};
        }
        data.error = event.toString();
        this.track('error', data, priority);
    };
    Mixpanel.prototype.breadcrumb = function (event, data) {
        this.track(event, data);
    };
    Mixpanel.prototype.flush = function () {
        this.pushAnalytics(true);
    };
    Mixpanel.prototype.nav = function (prevPage, newPage, deltaTime) {
        this.stateList.push(prevPage || 'load');
        this.stateDeltaList.push(deltaTime);
        this.track(this.EVENT_NAV, {
            current: newPage,
            previous: prevPage,
            dt: deltaTime,
        });
        this.navPage = newPage;
    };
    Mixpanel.prototype.pushAnalytics = function (force) {
        if (!this.initialized) {
            return;
        }
        var time = Date.now();
        var size = this.event_list.length;
        if ((size < this.MAX_CACHED_EVENTS &&
            !force &&
            time - this.lastEventsTime < this.BATCH_TIME_MS) ||
            size < this.MIN_BATCH_SIZE) {
            return;
        }
        this.lastEventsTime = time;
        var ev = {
            flush: force,
        };
        ev[this.EVENT_ACTION_COUNT] = size;
        ev['states'] = this.stateList;
        ev['state-times'] = this.stateDeltaList;
        var count = 0;
        for (var i = 0; i < size; i++) {
            var ev_str = JSON.stringify(this.event_list[i]);
            if (ev_str.length <= 250) {
                ev[this.EVENT_ACTION + '-' + count++] = ev_str;
            }
        }
        mixpanel === null || mixpanel === void 0 ? void 0 : mixpanel.track(this.EVENT_ACTION, ev);
        this.event_list = [];
        this.stateList = [];
        this.stateDeltaList = [];
    };
    return Mixpanel;
}());
export { Mixpanel };
export function initMixpanel(_a) {
    var apiKey = _a.apiKey;
    if (!apiKey) {
        return false;
    }
    analytics.registerModule(ZipAnalytics.MIXPANEL_KEY, new Mixpanel(apiKey, { ignore_dnt: true, ip: false }));
}
