var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { getUuid } from 'ziphy-web-shared/basic/helpers';
export function getStylesMode(mode, styles) {
    var array = Array.isArray(mode) ? mode : [mode];
    return array.map(function (x) { return styles[x]; });
}
export function simpleTagConverter(str, Tag) {
    if (str === void 0) { str = ''; }
    if (Tag === void 0) { Tag = 'em'; }
    var regex = new RegExp('<' + Tag + '>[\\s\\S]*?<\\/' + Tag + '>', 'ig');
    var matches = str.match(regex) || [];
    if (matches.length) {
        var lastPosition_1 = 0;
        var result_1 = [];
        matches.forEach(function (m) {
            var start = str.indexOf(m);
            result_1.push(str.substring(0, start));
            result_1.push(_jsx(Tag, { children: m.replace(/<\/?[^>]+(>|$)/g, '') }, getUuid()));
            lastPosition_1 = start + m.length;
            str = str.substring(lastPosition_1);
        });
        result_1.push(str);
        return result_1;
    }
    else {
        return str;
    }
}
export var T = function (_a) {
    var children = _a.children, tag = _a.tag, className = _a.className;
    var Tag = tag || 'div';
    var regex = new RegExp(/(\bhttps?:\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi);
    var regexAnchor = new RegExp(/\bhttps?:\/\//gi);
    return (children && (_jsx(Tag, __assign({ className: className }, { children: children.split(regex).map(function (item, index) {
            if (regex.test(item)) {
                var anchor = item.replace(regexAnchor, '');
                return (_jsx("a", __assign({ target: "_blank", href: item }, { children: anchor }), index));
            }
            return item;
        }) }))));
};
