var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
export function getUuid() {
    return uuidv4();
}
export function getRandomNumber(min, max) {
    return Math.ceil(Math.random() * (max - min) + min);
}
export function isAbsoluteEmpty(value) {
    if (_.isArray(value) || _.isObject(value)) {
        return _.isEmpty(value);
    }
    return (value !== 0 && !value) || String(value).trim() === '';
}
export function isJson(str) {
    try {
        JSON.parse(str);
        return true;
    }
    catch (e) {
        return false;
    }
}
export function pushOrUpdate(arr, value, _a) {
    if (arr === void 0) { arr = []; }
    var _b = _a === void 0 ? {} : _a, _c = _b.byKey, byKey = _c === void 0 ? 'id' : _c, _d = _b.pushTo, pushTo = _d === void 0 ? 'end' : _d, _e = _b.merge, merge = _e === void 0 ? false : _e;
    var index = _.findIndex(arr, function (x) { return x[byKey] === value[byKey]; });
    if (index === -1) {
        if (pushTo === 'start') {
            arr = __spreadArray([value], arr, true);
        }
        else {
            arr = __spreadArray(__spreadArray([], arr, true), [value], false);
        }
    }
    else {
        var tmp = _.cloneDeep(arr);
        if (merge) {
            tmp[index] = _.merge(tmp[index], value);
        }
        else {
            tmp[index] = value;
        }
        arr = tmp;
    }
    return arr;
}
