var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import _ from 'lodash';
import moment from 'moment-timezone';
import { getInitConfig } from 'ziphy-web-shared';
import { isJson } from 'ziphy-web-shared/basic/helpers';
function getStorageInstance(storage) {
    var instance = {
        _parsePath: function (pp, options) {
            if (options === void 0) { options = {}; }
            var withAppType = options.withAppType, withUserId = options.withUserId;
            if (_.isObject(pp) && pp.hasOwnProperty('name')) {
                return pp;
            }
            var _a = _.toPath(pp) || [], name = _a[0], path = _a.slice(1);
            if (withUserId || (name === 'userSettings' && _.isUndefined(withUserId))) {
                var userId = this.get('loggedInfo.userId');
                if (userId) {
                    name = [name, userId].join('_');
                }
                else {
                    return { name: -1, path: path };
                }
            }
            if (withAppType || (name === 'loggedInfo' && _.isUndefined(withAppType))) {
                name = [name, getInitConfig().appType].join('_');
            }
            return { name: name, path: path };
        },
        get: function (pp, defaultValue, options) {
            if (options === void 0) { options = {}; }
            var _a = this._parsePath(pp, options), name = _a.name, path = _a.path;
            var result = storage.getItem(name);
            if (typeof result === 'undefined' || result === 'undefined') {
                return defaultValue;
            }
            result = isJson(result) ? JSON.parse(result) : result;
            if (path.length > 0) {
                return _.get(result, path, defaultValue);
            }
            return result || defaultValue;
        },
        set: function (pp, payload, options) {
            if (options === void 0) { options = { lifeTime: null, lifeTimePath: null }; }
            var _a = this._parsePath(pp, options), name = _a.name, path = _a.path;
            var result;
            if (name === -1) {
                return false;
            }
            if (path.length > 0) {
                result = this.get(name, {}, options);
                _.set(result, path, payload);
            }
            else {
                result = payload;
            }
            if (options.lifeTime) {
                var expName = name;
                var expPath = path;
                if (options.lifeTimePath) {
                    var expParsed = this._parsePath(options.lifeTimePath, options);
                    expName = expParsed.name;
                    expPath = expParsed.path;
                }
                var expData_1 = {
                    path: __spreadArray([expName], expPath, true).filter(function (x) { return x; }).join('.'),
                    exp: moment().add(options.lifeTime, 'seconds'),
                };
                var existingTempData = this.get('temp', []);
                var result_1 = _.filter(existingTempData, function (x) { return x.path !== expData_1.path; });
                result_1.push(expData_1);
                storage.setItem('temp', JSON.stringify(result_1));
            }
            storage.setItem(name, JSON.stringify(result));
        },
        remove: function (pp, options) {
            if (options === void 0) { options = {}; }
            var _a = this._parsePath(pp, options), name = _a.name, path = _a.path;
            var result;
            if (name === -1) {
                return false;
            }
            if (path.length > 0) {
                result = this.get(name, {}, options);
                _.unset(result, path);
                storage.setItem(name, JSON.stringify(result));
            }
            else {
                storage.removeItem(name);
            }
        },
        clearTemp: function () {
            var _this = this;
            var result = [];
            var existingTempData = this.get('temp', []);
            _.forEach(existingTempData, function (x) {
                if (moment().isBefore(x.exp) && _this.get(x.path)) {
                    result.push(x);
                }
                else {
                    _this.remove(x.path);
                }
            });
            if (result.length) {
                storage.setItem('temp', JSON.stringify(result));
            }
            else {
                storage.removeItem('temp');
            }
        },
    };
    instance.clearTemp();
    return instance;
}
export var localStore = getStorageInstance(localStorage);
export var sessionStore = getStorageInstance(sessionStorage);
