import _ from 'lodash';
function deepToJS(value, deep) {
    if (deep === void 0) { deep = 30; }
    if (deep <= 0) {
        return value;
    }
    deep--;
    if (_.isArray(value)) {
        value = value.map(function (x) { return deepToJS(x, deep); });
    }
    else if (_.isMap(value)) {
        var tmp_1 = [];
        value.forEach(function (v, k) { return tmp_1.push({ mapId: k, value: deepToJS(v, deep) }); });
        return tmp_1;
    }
    else if (_.isObject(value)) {
        return _.mapValues(value, function (x) { return deepToJS(x, deep); });
    }
    return value;
}
console.js = function consoleJsCustom() {
    var args = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        args[_i] = arguments[_i];
    }
    console.log.apply(console, deepToJS(args));
};
