var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { useEffect } from 'react';
import _ from 'lodash';
import { isJson, isUrl } from 'ziphy-web-shared/basic/helpers';
var WebView = /** @class */ (function () {
    function WebView(props) {
        var _this = this;
        var _a, _b, _c;
        this.isEnabled = false;
        this.isTest = false;
        this.prefix = 'DefaultPrefix';
        this.postMessage = function (source, payload) {
            var _a;
            if (payload === void 0) { payload = {}; }
            if (!_this.isWebView)
                return;
            (_a = window.ReactNativeWebView) === null || _a === void 0 ? void 0 : _a.postMessage(JSON.stringify({ source: source, payload: payload }));
        };
        this.goTo = function (event, screen, params) {
            if (screen === void 0) { screen = ''; }
            if (params === void 0) { params = {}; }
            if (!_this.isWebView)
                return;
            if (_.isObject(event) && _.isFunction(event.preventDefault)) {
                event.preventDefault();
            }
            _this.postMessage(_this.prefix + 'GoTo', { screen: screen, params: params });
        };
        this.close = function (event) {
            if (!_this.isWebView)
                return;
            if (_.isObject(event) && _.isFunction(event.preventDefault)) {
                event.preventDefault();
            }
            _this.postMessage(_this.prefix + 'Close');
        };
        this.anchorClickHandler = function (event) {
            if (!_this.isWebView)
                return;
            var anchor = event.target.closest('a');
            if (anchor !== null) {
                var href = anchor.getAttribute('href');
                var target = anchor.getAttribute('target');
                if (isUrl(href) || _.includes(href, 'mailto:') || _.includes(href, 'tel:')) {
                    event.preventDefault();
                    _this.postMessage(_this.prefix + 'AnchorClick', { href: href, target: target });
                }
            }
        };
        this.log = function (payload) {
            if (!_this.isWebView)
                return;
            _this.postMessage(_this.prefix + 'ConsoleLog', payload);
        };
        this.isEnabled = (_a = props === null || props === void 0 ? void 0 : props.isEnabled) !== null && _a !== void 0 ? _a : this.isEnabled;
        this.isTest = (_b = props === null || props === void 0 ? void 0 : props.isTest) !== null && _b !== void 0 ? _b : this.isTest;
        this.prefix = (_c = props === null || props === void 0 ? void 0 : props.prefix) !== null && _c !== void 0 ? _c : this.prefix;
    }
    Object.defineProperty(WebView.prototype, "isWebView", {
        get: function () {
            return (!!window.hasOwnProperty('ReactNativeWebView') && this.isEnabled) || this.isTest;
        },
        enumerable: false,
        configurable: true
    });
    return WebView;
}());
export var webView = new WebView();
export var initWebView = function (props) {
    webView = new WebView(props);
};
export var useWebViewAnchorClickHandler = function (deps) {
    if (deps === void 0) { deps = []; }
    useEffect(function () {
        if (webView.isWebView) {
            document.addEventListener('click', webView.anchorClickHandler);
        }
        return function () {
            if (webView.isWebView) {
                document.removeEventListener('click', webView.anchorClickHandler);
            }
        };
    }, deps);
};
export var useWebViewListenPostMessage = function (source, callback, deps) {
    if (deps === void 0) { deps = []; }
    useEffect(function () {
        function handle(event) {
            if (event.origin !== window.location.origin) {
                return;
            }
            var data = isJson(event.data) ? JSON.parse(event.data) : event.data;
            if ((data === null || data === void 0 ? void 0 : data.source) !== source) {
                return;
            }
            callback({ payload: (data === null || data === void 0 ? void 0 : data.payload) || {} });
        }
        window.addEventListener('message', handle);
        return function () { return window.removeEventListener('message', handle); };
    }, __spreadArray([], deps, true));
};
