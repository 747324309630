var ZipAnalytics = /** @class */ (function () {
    function ZipAnalytics() {
        this.NOT_SET_ID = 'na';
        this.navTime = new Date().getTime();
        this.navPage = '';
        this.unique_id = this.NOT_SET_ID;
        this.modules = {};
    }
    ZipAnalytics.prototype.registerModule = function (key, module) {
        if (!module) {
            return;
        }
        if (!this.modules[key]) {
            module.register();
            this.modules[key] = module;
        }
    };
    ZipAnalytics.prototype.getModule = function (key) {
        return this.modules[key];
    };
    ZipAnalytics.prototype.startSession = function (id, profile) {
        var _a;
        if (this.unique_id !== id && id !== this.NOT_SET_ID) {
            this.unique_id = id;
            for (var key in this.modules) {
                (_a = this.modules[key]) === null || _a === void 0 ? void 0 : _a.startSession(id, profile);
            }
        }
    };
    ZipAnalytics.prototype.endSession = function () {
        var _a;
        if (this.unique_id !== this.NOT_SET_ID) {
            for (var key in this.modules) {
                (_a = this.modules[key]) === null || _a === void 0 ? void 0 : _a.endSession();
            }
            this.unique_id = this.NOT_SET_ID;
            this.flush();
        }
    };
    ZipAnalytics.prototype.flush = function () {
        var _a;
        for (var key in this.modules) {
            (_a = this.modules[key]) === null || _a === void 0 ? void 0 : _a.flush();
        }
    };
    ZipAnalytics.prototype.breadcrumb = function (event, data) {
        var _a;
        if (event) {
            for (var key in this.modules) {
                (_a = this.modules[key]) === null || _a === void 0 ? void 0 : _a.breadcrumb(event, data);
            }
        }
    };
    ZipAnalytics.prototype.error = function (event, data, priority) {
        var _a;
        if (priority === void 0) { priority = false; }
        if (event) {
            for (var key in this.modules) {
                (_a = this.modules[key]) === null || _a === void 0 ? void 0 : _a.error(event, data, priority);
            }
        }
    };
    ZipAnalytics.prototype.nav = function (page) {
        var _a;
        var tm = new Date().getTime();
        var dt = tm - this.navTime;
        if (page) {
            var subIdx = page.lastIndexOf('/');
            if (subIdx > 0) {
                page = page.substring(subIdx);
            }
        }
        for (var key in this.modules) {
            (_a = this.modules[key]) === null || _a === void 0 ? void 0 : _a.nav(this.navPage, page, dt);
        }
        this.navTime = tm;
        this.navPage = page;
    };
    ZipAnalytics.prototype.track = function (event, data, priority) {
        var _a;
        if (priority === void 0) { priority = false; }
        if (event) {
            for (var key in this.modules) {
                (_a = this.modules[key]) === null || _a === void 0 ? void 0 : _a.track(event, data, priority);
            }
        }
    };
    ZipAnalytics.MIXPANEL_KEY = 'mix';
    ZipAnalytics.RAYGUN_KEY = 'ray';
    ZipAnalytics.CRASHLYTICS_KEY = 'crash';
    ZipAnalytics.PORTAL_KEY = 'portal';
    return ZipAnalytics;
}());
export { ZipAnalytics };
export var analytics = new ZipAnalytics();
