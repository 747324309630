import i18n from 'i18next';
import { isAbsoluteEmpty } from 'ziphy-web-shared/basic/helpers';
function getI18nValue(key) {
    return i18n.exists(key) ? i18n.t(key) : key || '';
}
export function getEnumObjectValue(data, enums) {
    var result = { id: '', value: '', desc: '' };
    var id = typeof data === 'object' ? data.code : data;
    var text = typeof data === 'object' ? data.text : '';
    if (isAbsoluteEmpty(id)) {
        return result;
    }
    var config = enums[id];
    if (!config && (enums === null || enums === void 0 ? void 0 : enums.other))
        config = enums.other;
    if (!config)
        config = { id: 'custom', tKey: text || id };
    Object.entries(config).forEach(function (_a) {
        var key = _a[0], value = _a[1];
        if (key === 'id') {
            result.id = key;
        }
        if (typeof value === 'string') {
            if (key === 'tKey') {
                result.value = getI18nValue(value);
            }
            else if (key === 'tKeyDesc') {
                result.desc = getI18nValue(value);
            }
            else {
                result[key] = getI18nValue(value);
            }
        }
        else {
            result[key] = value;
        }
    });
    return result;
}
