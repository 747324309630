var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import _ from 'lodash';
// condition: {
//   and: [{ field: 'resource', eq: 'Observation' }],
//   or: [
//     { field: 'subtype', eq: ['medical_history'] },
//     { field: 'subtype', eq: ['medical_history', 'lab'] },
//   ],
// },
// condition: [
//   { field: 'resource', eq: 'Observation' },
//   { field: 'subtype', eq: ['medical_history', 'lab'] },
// ],
// type Condition = { field: 'resource' | 'subtype'; eq: string | string[] }
export var match = function (data, conditions, _a) {
    if (conditions === void 0) { conditions = []; }
    var _b = _a === void 0 ? {} : _a, _c = _b.byKey, byKey = _c === void 0 ? 'condition' : _c, _d = _b.findFirst, findFirst = _d === void 0 ? false : _d;
    function _iterateAnd(items, obj) {
        var isDone = true;
        if (!items) {
            return false;
        }
        _.forEach(items, function (item) {
            isDone = _matchConditions(item, obj);
            if (!isDone)
                return false;
        });
        return isDone;
    }
    function _iterateOr(items, obj) {
        var isDone = false;
        if (_.isArray(items) && _.isArray(items[0])) {
            _.forEach(items, function (item) {
                isDone = _iterateAnd(item, obj);
                if (isDone)
                    return false;
            });
        }
        else {
            _.forEach(items, function (item) {
                isDone = _matchConditions(item, obj);
                if (isDone)
                    return false;
            });
        }
        return isDone;
    }
    function _matchConditions(item, obj) {
        var isDone = true;
        var field = item.field, otherFields = __rest(item, ["field"]);
        var value = _.get(obj, field);
        _.forEach(otherFields, function (needle, type) {
            isDone = _matchValue(type, needle, value);
            if (!isDone) {
                return false;
            }
        });
        return isDone;
    }
    function _matchValue(type, needle, value) {
        var tmp;
        if (_.isArray(needle)) {
            needle = _.sortBy(needle);
        }
        if (_.isArray(value)) {
            value = _.sortBy(value);
        }
        switch (_.toLower(type)) {
            case 'eq':
                return _.isEqual(value, needle);
            case 'ne':
                return !_.isEqual(value, needle);
            case 'lt':
                return value < needle;
            case 'lte':
                return value <= needle;
            case 'gt':
                return value > needle;
            case 'gte':
                return value >= needle;
            case 'in':
                tmp = _.isArray(needle) ? needle : [needle];
                return tmp.every(function (x) { return _.includes(value, x); });
            case 'nin':
                tmp = _.isArray(needle) ? needle : [needle];
                return tmp.every(function (x) { return !_.includes(value, x); });
            case 'sin': // some in
                tmp = _.isArray(needle) ? needle : [needle];
                return tmp.some(function (x) { return _.includes(value, x); });
            case 'snin': // some nin
                tmp = _.isArray(needle) ? needle : [needle];
                return tmp.some(function (x) { return !_.includes(value, x); });
            case 'exists':
                tmp = typeof value === 'undefined' || value === '';
                return tmp !== needle;
        }
        return false;
    }
    var result = [];
    _.forEach(conditions, function (obj) {
        var condition = obj[byKey] || obj;
        var andFound;
        var orFound;
        if ((condition === null || condition === void 0 ? void 0 : condition.and) || _.isArray(condition)) {
            andFound = _iterateAnd(condition.and || condition, data);
        }
        else {
            andFound = true;
        }
        if (condition === null || condition === void 0 ? void 0 : condition.or) {
            orFound = _iterateOr(condition.or, data);
        }
        else {
            orFound = true;
        }
        if (andFound && orFound) {
            result.push(obj);
            if (findFirst) {
                return false;
            }
        }
    });
    return findFirst ? _.first(result) : result;
};
// pathWithConditions = [
//   'modifierExtension',
//   [{
//     field: 'url',
//     eq: 'https://fhir.ziphy.com/1.0/StructureDefinition/observation-travelLocation',
//   }],
//   'valueString',
// ]
// condition: {
//   and: [{ field: 'resource', eq: 'Observation' }],
//   or: [
//     { field: 'subtype', eq: ['medical_history'] },
//     { field: 'subtype', eq: ['medical_history', 'lab'] },
//   ],
// },
// condition: [
//   { field: 'resource', eq: 'Observation' },
//   { field: 'subtype', eq: ['medical_history', 'lab'] },
// ],
export var getByPath = function (data, pathWithConditions, multi) {
    if (multi === void 0) { multi = false; }
    var target = data;
    var path = __spreadArray([], pathWithConditions, true);
    var _loop_1 = function () {
        if (_.isEmpty(target)) {
            return "break";
        }
        var currentPathEl = path[0];
        path.shift();
        if (typeof currentPathEl === 'string') {
            // if path = 'string' just get data by path
            if (_.isArray(target)) {
                var newTarget_1 = [];
                _.forEach(target, function (item) {
                    var el = _.get(item, currentPathEl);
                    if (_.isArray(el)) {
                        newTarget_1 = _.concat(newTarget_1, el);
                    }
                    else {
                        newTarget_1.push(el);
                    }
                });
                target = newTarget_1;
            }
            else {
                target = _.get(target, currentPathEl);
            }
        }
        else {
            // if path = condition object extract data by condition
            var newTarget_2 = [];
            _.every(target, function (item) {
                if (!_.isEmpty(match(item, [currentPathEl]))) {
                    if (_.isArray(item)) {
                        _.concat(newTarget_2, item);
                    }
                    else {
                        newTarget_2.push(item);
                    }
                    if (!multi) {
                        return false; // stop iterating on first match
                    }
                }
                return true;
            });
            target = newTarget_2;
        }
    };
    while (path.length) {
        var state_1 = _loop_1();
        if (state_1 === "break")
            break;
    }
    return _.isArray(target) ? target : [target];
};
