export function customDataCleanup(body, key, replacement) {
  body = typeof body === 'string' ? body : JSON.stringify(body)
  let bodyNew = body

  const separator1 = `"${key}":"`
  const bodyArr = body.split(separator1)
  const valuePart = bodyArr[1]

  if (valuePart) {
    const separator2 = '"'
    const valuePartArr = valuePart.split(separator2)
    valuePartArr[0] = replacement.substring(0, valuePartArr[0].length)
    bodyArr[1] = valuePartArr.join(separator2)
    bodyNew = bodyArr.join(separator1)
  }

  return bodyNew
}

export function customDataCleanupMany(body, keyReplacementPairs) {
  let newBody = body
  keyReplacementPairs.forEach(function ([key, replacement]) {
    newBody = customDataCleanup(newBody, key, replacement)
  })
  return newBody
}

export function onBeforeSend(filterKeys) {
  const replacement = '[removed by filter]' // Like Raygun does.

  return function (payload) {
    if (payload) {
      const Breadcrumbs = payload && payload.Details && payload.Details.Breadcrumbs

      if (Breadcrumbs) {
        Breadcrumbs.forEach(function (breadcrumb) {
          if (breadcrumb.type === 'request') {
            const { CustomData } = breadcrumb

            if (CustomData.body) {
              CustomData.body = customDataCleanupMany(
                CustomData.body,
                filterKeys.map(function (key) {
                  return [key, replacement]
                }),
              )
            }
          }
        })
      }
    }

    return payload
  }
}
