import { initMixpanel } from './mixpanel/mixpanel';
import { initRaygun } from './raygun/raygun';
export { setRG } from './raygun/raygun';
export { analytics } from './zipAnalytics';
export var initAnalytics = function (props, options) {
    if (window.location.hostname === 'localhost') {
        return;
    }
    if (props.raygun) {
        initRaygun({
            apiKey: props.raygun.apiKey,
            appEnv: options.appEnv,
            appType: options.appType,
            appBrand: options.appBrand,
            appVersion: options.appVersion,
            appRevision: options.appRevision,
        });
    }
    if (props.mixpanel) {
        initMixpanel({ apiKey: props.mixpanel.apiKey });
    }
};
