export var NotesLayoutGroups;
(function (NotesLayoutGroups) {
    NotesLayoutGroups["CONDITION_AND_HISTORY"] = "condition_and_history";
    NotesLayoutGroups["VISIT"] = "visit";
})(NotesLayoutGroups || (NotesLayoutGroups = {}));
export var NotesSectionsKeys;
(function (NotesSectionsKeys) {
    NotesSectionsKeys["ALLERGIES"] = "allergies";
    NotesSectionsKeys["ASSESSMENTS"] = "assessments";
    NotesSectionsKeys["BILLING"] = "billing";
    NotesSectionsKeys["CONDITIONS"] = "conditions";
    NotesSectionsKeys["EXAMINATIONS"] = "examinations";
    NotesSectionsKeys["FAMILY_HISTORY"] = "familyHistory";
    NotesSectionsKeys["HPI"] = "hpi";
    NotesSectionsKeys["MEDICATIONS"] = "medications";
    NotesSectionsKeys["NEXT_APPOINTMENT"] = "nextAppointment";
    NotesSectionsKeys["ORDERS"] = "orders";
    NotesSectionsKeys["REFERRALS"] = "referrals";
    NotesSectionsKeys["REVIEW_OF_SYSTEMS"] = "reviewOfSystems";
    NotesSectionsKeys["SOCIAL_HISTORY"] = "socialHistory";
    NotesSectionsKeys["SURGICAL_HISTORY"] = "surgicalHistory";
    NotesSectionsKeys["VITALS"] = "vitals";
})(NotesSectionsKeys || (NotesSectionsKeys = {}));
export var NotesEncounterStatuses;
(function (NotesEncounterStatuses) {
    NotesEncounterStatuses["NOT_STARTED"] = "notStarted";
    NotesEncounterStatuses["STARTED"] = "started";
    NotesEncounterStatuses["CANCELED"] = "canceled";
    NotesEncounterStatuses["FINISHED"] = "finished";
})(NotesEncounterStatuses || (NotesEncounterStatuses = {}));
export var NotesSectionCompletenessStatuses;
(function (NotesSectionCompletenessStatuses) {
    NotesSectionCompletenessStatuses["EMPTY"] = "empty";
    NotesSectionCompletenessStatuses["UNVERIFIED"] = "unverified";
    NotesSectionCompletenessStatuses["COMPLETED"] = "completed";
})(NotesSectionCompletenessStatuses || (NotesSectionCompletenessStatuses = {}));
export var NotesSectionReviewStatuses;
(function (NotesSectionReviewStatuses) {
    NotesSectionReviewStatuses["EMPTY"] = "empty";
    NotesSectionReviewStatuses["REVIEWED"] = "reviewed";
    NotesSectionReviewStatuses["NOT_REVIEWED"] = "notReviewed";
})(NotesSectionReviewStatuses || (NotesSectionReviewStatuses = {}));
export var ApptTypes;
(function (ApptTypes) {
    ApptTypes["ONSITE"] = "onsite";
    ApptTypes["VIRTUAL"] = "virtual";
    ApptTypes["CLINIC"] = "clinic";
})(ApptTypes || (ApptTypes = {}));
export var ApptGroupTypes;
(function (ApptGroupTypes) {
    ApptGroupTypes["GROUP"] = "group";
    ApptGroupTypes["FAMILY"] = "family";
    ApptGroupTypes["INDIVIDUAL"] = "individual";
})(ApptGroupTypes || (ApptGroupTypes = {}));
export var NotesSources;
(function (NotesSources) {
    NotesSources["INTAKE"] = "intake";
    NotesSources["NOTES"] = "notes";
})(NotesSources || (NotesSources = {}));
export var TabViews;
(function (TabViews) {
    TabViews["current"] = "current";
    TabViews["history"] = "history";
    TabViews["review"] = "review";
})(TabViews || (TabViews = {}));
