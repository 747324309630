var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import _ from 'lodash';
import { BaseService } from 'ziphy-web-shared/basic/api/baseService';
var Vouchers = /** @class */ (function (_super) {
    __extends(Vouchers, _super);
    function Vouchers(props) {
        return _super.call(this, props) || this;
    }
    Vouchers.prototype.create = function (params, options) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.api.fetchLoggedV3('vouchers.create', params, options)];
            });
        });
    };
    Vouchers.prototype.read = function (params, options) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.api.fetchLoggedV3('vouchers.read', params, options)];
            });
        });
    };
    Vouchers.prototype.update = function (params, options) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.api.fetchLoggedV3('vouchers.update', params, options)];
            });
        });
    };
    Vouchers.prototype.delete = function (params, options) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.api.fetchLoggedV3('vouchers.delete', params, options)];
            });
        });
    };
    Vouchers.prototype.list = function (params, options) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.api.fetchLoggedV3('vouchers.list', params, options)];
            });
        });
    };
    /*
     * Custom Methods
     */
    Vouchers.prototype.search = function (params, options) {
        return __awaiter(this, void 0, void 0, function () {
            var id, code, practiceIds, practiceServiceId, filters;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        id = params.id, code = params.code, practiceIds = params.practiceIds, practiceServiceId = params.practiceServiceId;
                        filters = [{ eq: ['is_active', true] }];
                        if (id) {
                            filters.push({ eq: ['id', id] });
                        }
                        else if (code) {
                            filters.push({ imatch: ['code', '^' + _.escapeRegExp(code) + '$'] });
                        }
                        if (_.isArray(practiceIds) && practiceIds.length) {
                            filters.push({ in: ['practice_id', practiceIds] });
                        }
                        if (_.isNumber(practiceServiceId)) {
                            filters.push({ eq: ['practice_service_id', practiceServiceId] });
                        }
                        return [4 /*yield*/, this.list({
                                expand: __assign({ voucher: ['practiceId', 'practiceServiceId'] }, params.expand),
                                filter: { and: filters },
                            }, options)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    return Vouchers;
}(BaseService));
export { Vouchers };
