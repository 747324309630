var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect, useRef } from 'react';
function useClickOutside(callback, isObserve) {
    if (isObserve === void 0) { isObserve = true; }
    var ref = useRef(null);
    var callbackRef = useRef();
    useEffect(function () {
        callbackRef.current = callback;
    });
    useEffect(function () {
        if (!isObserve)
            return;
        function handleClick(event) {
            if (callbackRef.current && ref.current && !ref.current.contains(event.target)) {
                callbackRef.current(event);
            }
        }
        document.addEventListener('click', function (e) { return handleClick(e); }, true);
        return function () {
            document.removeEventListener('click', function (e) { return handleClick(e); }, true);
        };
    }, [isObserve]);
    return ref;
}
function ClickOutside(_a) {
    var children = _a.children, onClick = _a.onClick, Tag = _a.tag, isObserve = _a.isObserve, otherProps = __rest(_a, ["children", "onClick", "tag", "isObserve"]);
    var ref = useClickOutside(onClick, isObserve);
    if (Tag === 'form') {
        return (_jsx("form", __assign({ ref: ref }, otherProps, { children: children })));
    }
    return (_jsx("div", __assign({ ref: ref }, otherProps, { children: children })));
}
export default ClickOutside;
