export function isBase64(str) {
    if (str === void 0) { str = ''; }
    return typeof str === 'string' && str.startsWith('data:') && str.includes('base64');
}
export function saveImageToBase64(file) {
    return new Promise(function (resolve, reject) {
        var reader = new FileReader();
        var mime = file.type;
        reader.onload = function (_a) {
            var result = _a.target.result;
            if (typeof result === 'string') {
                var str = btoa(result);
                resolve("data:".concat(mime, ";base64,").concat(str));
            }
            else {
                reject(new Error('File reading failed'));
            }
        };
        reader.onerror = reject;
        reader.readAsBinaryString(file);
    });
}
export function getImageDimensions(file) {
    return new Promise(function (resolve, reject) {
        var img = new Image();
        var objectUrl = URL.createObjectURL(file);
        img.onload = function () {
            var _a = this, width = _a.width, height = _a.height;
            URL.revokeObjectURL(objectUrl);
            resolve({ width: width, height: height });
        };
        img.onerror = reject;
        img.src = objectUrl;
    });
}
