import moment from 'moment-timezone';
var PersonAgeLimitConfig = {
    default: { min: 0, max: 120 },
    profile: { min: 18, max: 120 },
    patient: { min: 3, max: 120 },
};
export var personAgeLimit = function (type) {
    var preset = PersonAgeLimitConfig[type] || PersonAgeLimitConfig['default'];
    var currentDate = moment().format();
    var from = moment(currentDate).subtract(preset.max, 'years').format();
    var to = moment(currentDate).subtract(preset.min, 'years').format();
    return { from: from, to: to };
};
export function splitName(name, type) {
    if (name === void 0) { name = ''; }
    if (type === void 0) { type = 'default'; }
    var matches = name
        .replace(new RegExp(String.fromCharCode(160), 'g'), ' ')
        .split(' ')
        .filter(function (x) { return x; });
    var firstName = '';
    var lastName = '';
    if (matches && matches.length) {
        if (type === 'consistently') {
            firstName = matches[0];
            lastName = matches[1];
        }
        else {
            if (matches.length > 1) {
                firstName = matches.slice(0, -1).join(' ');
                lastName = matches[matches.length - 1];
            }
            else {
                firstName = matches[0];
            }
        }
    }
    return [firstName, lastName].filter(function (x) { return x; });
}
export function getFullName(_a) {
    var _b = _a === void 0 ? {} : _a, firstName = _b.firstName, lastName = _b.lastName;
    return [firstName, lastName]
        .map(function (i) { return i && i.trim(); })
        .filter(function (i) { return i; })
        .join(' ');
}
